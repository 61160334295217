<template>
  <div class="d-flex justify-content-between align-items-center flex-wrap">
    <div class="d-flex flex-wrap py-2 mr-3" v-if="showPagination">
      <a class="btn btn-icon btn-sm btn-light-primary mr-2 my-1" disabled v-on:click="start()"><i
          class="flaticon2-fast-back"></i></a>
      <a class="btn btn-icon btn-sm btn-light-primary mr-2 my-1" v-on:click="prev()"><i
          class="flaticon2-back"></i></a>

      <div class="d-flex align-items-center py-3 mr-2 my-1"
           style="padding: 0 !important; margin: 0 30px 0 30px !important;">
        <input class="form-control form-control-sm datatable-pager-link datatable-pager-link-number datatable-pager-link-active"
               v-model="currentPage" style="width: 50px"/> / {{ totalPages }}
      </div>

      <a class="btn btn-icon btn-sm btn-light-primary mr-2 my-1" v-on:click="next()"><i
          class="flaticon2-next"></i></a>
      <a class="btn btn-icon btn-sm btn-light-primary mr-2 my-1" disabled v-on:click="end()"><i
          class="flaticon2-fast-next"></i></a>
    </div>
    <div class="d-flex align-items-center py-3">
      <div class="d-flex align-items-center">
        <div class="mr-2 text-muted" v-show="showLoading">Загрузка...</div>
        <div class="spinner spinner-primary mr-10" v-show="showLoading"></div>
      </div>

      <b-form-select
          v-model="perpage" 
          v-on:change="perPageChange"
          :options="perPageOptions"
          style="width: 75px;"
      ></b-form-select>
      <span class="datatable-pager-detail">Показано {{ current }} из {{ total }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  data: function () {
    return {
      totalPages: 0,
      currentPage: 1,
      showLoading: false,
      showPagination: false,
      perPageOptions:[10, 20, 30, 40, 50, 100]
    }
  },
  watch: {
    total: {
      immediate: true,
      handler: function () {
        this.recalc();
      }
    },

    loading: {
      immediate: true,
      handler: function (val) {
        this.showLoading = val;
      }
    },
    perpage: {
      immediate: true,
      handler: function () {
        this.recalc();
      }
    }
  },
  created: function () {
    this.recalc();
  },
  props: ['total', 'current', 'loading', 'perpage'],
  methods: {
    start: function (message, event) {

      if (event) {
        event.preventDefault()
      }
      this.currentPage = 1;
      this.pageChange();
    },
    prev: function (message, event) {

      if (event) {
        event.preventDefault()
      }
      if (this.currentPage > 1) {
        this.currentPage--;
      }
      this.pageChange();
    },
    next: function (message, event) {

      if (event) {
        event.preventDefault()
      }
      if (this.totalPages > this.currentPage) {
        this.currentPage++;
      }

      this.pageChange();
    },
    end: function (message, event) {

      if (event) {
        event.preventDefault()
      }
      this.currentPage = this.totalPages;

      this.pageChange();
    },
    recalc: function () {

      if (this.total == 0) {
        this.showPagination = false;

        return;
      }

      this.showPagination = true;

      this.totalPages = Math.ceil(this.total / this.perpage);
    },
    pageChange: function () {
      this.$emit('open-page', this.currentPage);
    },
    perPageChange: function () {
      this.currentPage = 1;
      this.$emit('per-page-change', this.perpage);
    },
    setToFirstPage: function () {
      this.currentPage = 1;
    }
  }
}
</script>